import "bootstrap";
import iziToast from "izitoast";
import Rails from "@rails/ujs";

import './data-confirm-modal/data-confirm-modal';
import initializeApp from "./initializeApp";

iziToast.settings({
  timeout: 8000,
  position: "topRight"
});

$(document).on('change', '[data-autosubmit=change]', function(event) {
  $(this).closest('form').submit();
});

document.addEventListener("turbolinks:load", function() {
  initializeApp();

  $('.select-all').click(function(e){
    var checked = e.currentTarget.checked;
    $('.list-item-checkbox').prop('checked', checked);
  });

  var lastChecked = null;
  $('.list-item-checkbox').click(function(e){
    if(!lastChecked) {
      lastChecked = this;
      return;
    }
    if(e.shiftKey) {
      var from = $('.list-item-checkbox').index(this);
      var to = $('.list-item-checkbox').index(lastChecked);

      var start = Math.min(from, to);
      var end = Math.max(from, to) + 1;

      $('.list-item-checkbox').slice(start, end)
        .filter(':not(:disabled)')
        .prop('checked', lastChecked.checked);
    }
    lastChecked = this;
  });

  $('.addressees-container input.list-item-checkbox').change(function(){
    const checkeds = $(this).closest('table').find("input:checked");
    if(checkeds.length > 0){
      $('.addressees-container input[type=submit]').prop('disabled', false)
    }else{
      $('.addressees-container input[type=submit]').prop('disabled', true)
    }
  })
  $('.addressees-container input#select_all').change(function(){
    if($(this).prop('checked')){
      $('.addressees-container input[type=submit]').prop('disabled', false)
    }else{
      $('.addressees-container input[type=submit]').prop('disabled', true)
    }
  })
})

